import React, {useEffect, useState} from 'react';

import {FiX,FiUserCheck} from 'react-icons/fi';
import './PesquisaCliente.css';
import {get} from '../../store/config/register';
import useTable from "../../components/table/useTable";
import TableFooter from "../../components/table/TableFooter";





export default function PesquisaCliente({conteudo, close}){

  const [pesquisa,setPesquisa]=useState('');
  const [clientes,setClientes]=useState([]);
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(clientes, page, 10);



  function buscaFuncionario(nome){
    if (isNaN(nome)){
      const clies = get("/Funcionario/v1/search/"+nome);
        if(clies != null){
          clies.then(resp =>{
            console.log(resp);
              setClientes(resp);
          })
      }
    }else{
      const clies = get("/Funcionario/v1/matricula/"+nome);
        if(clies != null){
          clies.then(resp =>{
              setClientes(resp);
          })
    }
  }
}


  useEffect(()=>{
    setPesquisa(conteudo.descricao);
   //buscaFuncionario(pesquisa);
  },[]);

  function  updateField( event){
    setPesquisa(event.target.value );
}


  function renderForm(){
    return (
      <div className="modalPesquisa">
        <div className="containerPesquisa">
      <button className="closePesquisa" onClick={ close }>
        <FiX size={23} color="#FFF" />
        Voltar
      </button>

      <div>
        <h2>Pesquisa de Funcionário</h2>

        <div className="row1">
          <span>
          Funcionário: 
          </span>
          <div className="input-group mb-3">
                <input type="text" class="form-control" name="pesquisa"  value={pesquisa} placeholder="Pesquise um funcionário" aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={e => updateField(e)}/>
                        <div className="input-group-append">
                        <button className="btn btn-info" type="button"  onClick={() => buscaFuncionario(pesquisa)}>Pesquisar</button>
                    </div>
                </div>
        </div>
  
      </div>
      {renderTable()}
    </div>
   
  </div>
    );
    
  }

  function renderTable(){
    return(    
  <div className='tabela'>
      <hr/>
      <table>
        <thead>
          <tr>
            <th scope="col">Matricula</th>
            <th scope="col">Nome</th>
            <th scope="col">#</th>
          </tr>
        </thead>
          <tbody>
            {slice === undefined ? null :slice.map((item, index)=>{
              return(
                <tr key={index}>
                  <td data-label="Matricula">{item.matricula}</td>
                  <td data-label="Nome">{item.nome}</td>
                  <td data-label="#">
                    {item.img ? ( <button className="action" style={{backgroundColor: '#90EE90' }} onClick={() => close(item)} >
                    <FiUserCheck color="#FFF" size={17} />
                    </button>):(
                       <button className="action" style={{backgroundColor: '#FF0000' }} onClick={() => close(item)} >
                       <FiUserCheck color="#FFF" size={17} />
                       </button>
                    )}
                   
                  </td>
                </tr>
              )     
            })}
          </tbody>
        </table>
        <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
  </div>
    );
  }
    return (
       <div>
        
        {renderForm()}
       </div>        
       
    );
}