import React, {useState} from 'react';
import Main from '../../components/template/Main';

import './Auth.css'
import logo from '../../assets/imgs/logo.jpg'
import ToastAnimated from '../../components/Toast/Toast'
import {post} from '../../store/config/register';
import {setLocalStorage,getLocalStorage} from '../../store/config/storage';
import {useHistory} from 'react-router-dom';
import { toast } from 'react-toastify';
import { authKey} from "../../global"

var initialState = {  showSignup: false,
    login: {
        login: '',
        senha:'',
        id_sistema: 1
    },
toast: {
    tipo: '',
    message: ''
} };

const headerProps = {
    icon: "key",
    title: "Login",
    subtitle: "Login do Sistema"
}

export default function Auth(){

    const history = useHistory();
    const [login,setLogin]=useState({...initialState.login});

    function  updateField( event){
        const logi ={...login}
        logi[event.target.name] =event.target.value
        setLogin(logi);
    }

    async function  signin(){
        const lo=await post('Login/v1', login);
        console.log(lo);
        if(lo !== null && lo.id !== undefined){
            
              let user=  montaUser(lo);
                //this.$store.commit('setUser',res.data)
                setLocalStorage(authKey, JSON.stringify(user),60);
               // this.$router.push({ path: '/'})
               history.push("/");
               window.location.reload();
        }else{
            if(lo !== null){
                toast.error(lo.messagem);
            }
            
        }
                
    }
    
    function montaUser(obj){

        
        let tokenApi= getLocalStorage("acessToken");
     
        return  {
         id: obj.id,
         id_grupo: obj.id_grupo,
         id_sistema: obj.id_sistema,
         login: obj.login,
         nome: obj.nome,
         empresa_id:obj.empresa_id,
         token: tokenApi
     }
         }

    function renderForm(){

        return (
        <div class="auth-content">
            <div class="auth-modal">
                <img src= {logo} width="200" alt="Logo"/>
                <hr/>
                 <div class="auth-title"> { 'Login'} </div>       
              <input  value={login.login} name="login" placeholder="Usuario" type="text" onChange={(e) =>updateField(e)}></input>
              <input  value={login.senha} name="senha" placeholder="Senha" type="password" onChange={(e) =>updateField(e)}></input>    
            <ToastAnimated />
             <button onClick={signin}>Entrar</button>     
              
            </div>
        </div>
        );
    }
    return (
        <Main {...headerProps}>
            {renderForm()}
        </Main>
    );
}