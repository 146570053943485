import React,{useEffect, useState} from "react";
import Main from "../../components/template/Main";
import {FiX,FiSend,FiSearch, FiPrinter} from 'react-icons/fi';
import { useHistory } from "react-router-dom";
import { authKey} from "../../global";
import {get,post,del, put} from '../../store/config/register';
import {getLocalStorage} from '../../store/config/storage';
import { toast } from "react-toastify";
import { format,parseISO } from "date-fns";
import  $ from 'jquery';
import useTable from "../../components/table/useTable";
import TableFooter from "../../components/table/TableFooter";


const initialState ={
    lote: {
      id: 0,
      id_empresa: 0,
      data_abertura: new Date(),
      data_fechamento: null,
      data_impressao: null,
       }
  }

  let id=0;
  

  const headerProps = {
    icon: "print",
    title: "Lotes de Cartões",
    subtitle: "Lista de Impressão"
  }

export default function ImpressaoLoteCartao(){
  const history = useHistory();
    const [lotes,setLotes]=useState([]);
    const [lote,setLote]=useState([]);
    const [pesquisa,setPesquisa]=useState({id: 0, descricao: ''});
    const [showPostPesquisa, setShowPostPesquisa] = useState(false);
    const [idEmpresa, setIdEmpresa]= useState(0);
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(lote, page, 10);
    const [apagar,setApagar]=useState(false);
    id=initialState.lote.id;


    useEffect(()=>{
        carregaEmpresa();
        if(idEmpresa !== 0){
       //     setLote({...lote,id_empresa: idEmpresa}); 
            
        }

        if(id !== 0){
            buscaLoteCliente();
        }
 
    },[idEmpresa]);

   
    function carregaEmpresa(){
        let valor= getLocalStorage(authKey);
        valor =JSON.parse(valor)
        setIdEmpresa(valor.empresa_id)
         buscaLote(valor.empresa_id)
    }

    async function ExisteFoto(id){
      let existe = await get(`/Imagem/v1/Existe/${id}`)    
      return existe.exist;
    }

  async function buscaLote(idEmpresa){
        const lot = await get(`/LoteImpCartao/v1/All/Empresa/${idEmpresa}`);
        if (lot!== null && lot !== ""){
            setLote(lot);
            if(lot.id >0){
            //  buscaLoteCliente(lot.id)
            }

        }
        }

    async function SaveLote(){
     await post("/LoteImpCartao/v1",lote)
     
    }

    async function FecharLote(lote_id, quantidade){

      let result =  await put(`/LoteImpCartao/v1/close/${lote_id}`)

      if(result !== undefined){
        let message ={
          Subject: "[HSM] Lote para impressão nº: "+lote_id,
          Content: "Boa tarde, \nFoi realizado o fechamento do lote "+lote_id+" para impressão dos cartões. \nQuantidade: " +quantidade
        }
  
        await post("/Email/v1",message)

        toast.success("Lote fechado e enviado ao responsáveis para impressão dos cartões.");
      }
      
      window.location.reload();
    }

    async function deleteLote(id){
      await del(`/LoteImpCartaoCliente/v1/${id}`)
      toast.success('cliente apagado do lote.')
      window.location.reload();
     }

    async function SaveLoteCliente(id){
      
      let item =  {
        lote_id: lote.id,
        cliente_id: id,
        matricula: 0
      }
      
      await post("/LoteImpCartaoCliente/v1",item)
      buscaLoteCliente(lote.id);
      window.location.reload();
     }

     function PrintLote(lote_id,qtde){
      history.push(`/Impressao/Pessoas?lote=${lote_id}&qtde=${qtde}`)
     }
  
    async function buscaLoteCliente(lote_id,fechamento){
        const lots = await get(`/LoteImpCartaoCliente/v1/Lote/${lote_id}`);
        if(lots != null){
          if(Array.isArray(lots)){
            setLotes(lots);
            setApagar(fechamento === null ? true : false);
          }else {
            lots.then(resp =>{
              setLotes(resp);
         })
          }
          $('table tr').click(function() { //Once any element with class "table_row" is clicked
            $('table tr').removeClass('selected'); // "Unselect" all the rows
            $(this).addClass('selected'); // Select the one clicked
        });
        }

      
    }

    
    function renderForm(){
        return (
            <div>
                <hr/>
                <div className="botao">
                    <button className="btn btn-primary" onClick={e =>SaveLote()}>
                      Abrir
                    </button>
                    <button className="btn btn-dark ml-2" onClick={e => FecharLote()}>
                        Fechar
                    </button>

                </div>
            </div>
        );
    }

    function  updateFieldPesquisa(event){
        const pesq ={...pesquisa}
        pesq[event.target.name] =event.target.value   
        setPesquisa(pesq);
}

function togglePostModal(item){
    setShowPostPesquisa(!showPostPesquisa) //trocando de true pra false
    if(item.id !== 0){
     let e= ExisteFoto(item.id );
      e.then(resp =>{
        if(resp === true){
          SaveLoteCliente(item.id)
        }else{
          alert('Não Tem foto')
        }
      })
         
    }
}

function renderTableCabec(){
  return(    
<div className='tabela'>
    <table>
      <thead>
        <tr>
          <th scope="col">Lote</th>
          <th scope="col">Data</th>
          <th scope="col">Usuário</th>
          <th scope="col">QTDE</th>
          <th scope="col">Impressão</th>
          <th scope="col">Integração</th>
          <th scope="col">#</th>
        </tr>
      </thead>
        <tbody>
          {slice.length === 0 ? null :slice.map((item, index)=>{

           if(item.fechamento === null){
              id =item.lote;
           }

            return(
              <tr key={index} >
                <td data-label="Lote">{item.lote}</td>
                <td data-label="Data">{format(parseISO(item.data),"dd/MM/yyyy")}</td>
                <td data-label="Usuário">{item.usuario}</td>
                <td data-label="QTDE">{item.qtde}</td>
                <td data-label="Impressão">{item.impressao !== null ? format(parseISO(item.impressao),"dd/MM/yyyy HH:mm"):item.impressao}</td>
                <td data-label="Integração">{item.integracao !== null ? format(parseISO(item.integracao),"dd/MM/yyyy HH:mm"):item.integracao
                
                }</td>
                <td data-label="#">
                  <button className="action" style={{backgroundColor: '#71D9D7' }} onClick={(e) => buscaLoteCliente(item.lote,item.fechamento)} >
                  <FiSearch color="#FFF" size={17} />
                  </button>
                  <button className="action" style={{backgroundColor: '#71D9D7' }} onClick={(e) => PrintLote(item.lote,item.qtde)} >
                  <FiPrinter color="#FFF" size={17} />
                  </button>
                  {item.fechamento === null ? 
                (
                  <button className="action" style={{backgroundColor: '#59E464' }} onClick={(e) => FecharLote(item.lote, item.qtde)} >
                  <FiSend color="#FFF" size={17} />
                  </button>
                ) 
                : 
                null
                }
                </td>
               
              </tr>
            )     
          })}
        </tbody>
      </table>
      <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
</div>
  );
}

    function renderTable(){
     
        return(    
      <div className='tabela'>
          <hr/>
          <table>
            <thead>
              <tr>
                <th scope="col">Matrícula</th>
                <th scope="col">Funcionário</th>
                <th scope="col">Setor</th>
                <th scope="col">Impresso</th>
                <th scope="col">Integracao</th>
                <th scope="col">#</th>
              </tr>
            </thead>
              <tbody>
              {console.log(lotes)}
                {lotes === undefined ? null :lotes.map((item, index)=>{
                  return(
                    <tr key={index}>
                      <td data-label="Matrícula">{item.matricula}</td>
                      <td data-label="Funcionário">{item.nome}</td>
                      <td data-label="Setor">{item.setor}</td>
                      <td data-label="Impresso">{item.impressao !== null ? format(parseISO(item.impressao),"dd/MM/yyyy HH:mm"):item.impressao }</td>
                      <td data-label="Integracao">{item.integracao !== null ? format(parseISO(item.integracao),"dd/MM/yyyy HH:mm"):item.integracao }</td>
                      {apagar ? (
                        <td data-label="#">
                        <button className="action" style={{backgroundColor: '#ff0000' }} onClick={(e) => deleteLote(item.id)} >
                        <FiX color="#FFF" size={17} />
                        </button>
                        </td>
                      ) : null}
                      
                    </tr>
                  )     
                })}
              </tbody>
            </table>
      </div>
        );
    }
   
    return (
        <Main {...headerProps}>
          {renderTableCabec()}
              {lote.length === 0 ? null :renderTable()}
        </Main>
    
    );


} 