import React,{useEffect, useRef,useState} from "react";
import ReactToPrint from 'react-to-print';

import Main from '../../components/template/Main';
import ImpressaoLotePessoas from "./ImpressaoLotePessoas";

const headerProps = {
    icon: "print",
    title: "Impressão",
  
  }

export default function TelaImpressaoLotePessoas(props){
    let componentRef = useRef();
    const query = new URLSearchParams(props.location.search);
    const Lote_id=query.get('lote')
    const Qtde=query.get('qtde')
    const Data=query.get('data')

   
 
    function renderForm(){
      return (
        <Main {...headerProps}>
        <ImpressaoLotePessoas Lote_id={Lote_id} Qtde={Qtde} ref={(el) => (componentRef = el)}/>
  
        <ReactToPrint
          trigger={() => <button className="btn btn-primary">Imprimir!</button>}
          content={() => componentRef}
        />
  
      </Main>
      );
    
    }

    return renderForm();
}