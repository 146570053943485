import React,{useEffect, useState} from "react";
import Main from "../../components/template/Main";

import { FiUpload } from 'react-icons/fi';
import excelimg from '../../assets/imgs/excel.png';
import {ExcelRenderer, OutTable} from 'react-excel-renderer';

const initialState ={
    arquivo: {
      id: 0,
     caminho: ''
    }
  }

  let idempresa=0;
  
const headerProps = {
    icon: "file",
    title: "Importação de Dados",
    subtitle: "Importação de Arquivo"
  }
export default function ImportacaoDados() {

    const [arquivo,setArquivo]=useState(initialState.arquivo);
    const [excel,setExcel]=useState({});

    function  updateField(event){
        const arqu ={...arquivo}
        arqu[event.target.name] =event.target.value   
        setArquivo(arqu);
}

    async function handleExcel(e){
        e.preventDefault();
            if(e.target.files[0]){
              console.log(e.target);
            const file=e.target.files[0];
            setArquivo({...arquivo, caminho:file.mozFullPath });
            await LerExcel(file);
            }
  }

  async function LerExcel(file){     

    await ExcelRenderer(file, (err, resp) => {
        if(err){
          console.log(err);            
        }
        else{
          setExcel({
            cols: resp.cols,
            rows: resp.rows
          });
  
          saveExcel(resp.rows);
        }

   
      }); 
  }
  
 

  function saveExcel(rows){
    let lista=convertObject(rows);
    lista.forEach(item =>{
    if(isNaN(item.matricula) == false) {
       // saveFuncionario(item);
      }
    });

    alert('Arquivo Excel importado com sucesso!');
   // setExcel({});
  }

  function convertObject(rows){
     
    let lista=[];
    if(rows !== undefined){
      let columns = rows[0];
      
      for (var i = 0; i < rows.length; ++i){
        var rv = {};
        if(rows[i][1] !== ""){     

          rv = {
            id: 0,
            id_empresa: idempresa,
            matricula: rows[i][0],
            nome: rows[i][1],
            rg: rows[i][4],
            nome_setor:rows[i][3],
            data_admissao: null,
            nome_reduzido: rows[i][2]
            }
      }
        lista.push(rv);
      }
    }
   
    return lista;
 
}
    function renderForm(){
        return (
            <div className="form">
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                          
                              <label className="label-avatar2">
                              <img src={excelimg} width="80" height="80" alt="Foto de perfil do usuario" />
                              <span>
                                  <FiUpload color="#FFF" size={25} />
                                </span>
                              <input type="file"  onChange={handleExcel}  /><br/>
                             
                              </label>
                      </div>
                      </div>
                <div className="row1">
                    <span>
                    Caminho: 
                    </span>
                <div className="input-group mb-3">
                <input type="text" class="form-control" name="caminho"  value={arquivo.caminho} placeholder="Pesquise um funcionário" aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={e => updateField(e)}/>
                        <div className="input-group-append">
                        <button className="btn btn-info" type="button"  onClick={() => true}>Buscar</button>
                    </div>
                </div> 
                </div>
                <h1>Importação</h1>
                {excel.rows !== undefined ? (
                <OutTable   data={excel.rows} columns={excel.cols} tableClassName="ExcelTable2007" tableHeaderRowClass="heading" />
                ) : 
                null
                }               
            </div>
        );
    }
    return (
        <Main {...headerProps}>
        {renderForm()}
        </Main>
    );
}