import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';



import Home from '../pages/Home/Home';
import Funcionario from '../pages/Funcionario/Funcionario';
import Auth from '../pages/Auth/Auth';
import ImportacaoDados from '../pages/Importacao/ImportacaoDados';
import ImpressaoLoteCartao from '../pages/Impressao/ImpressaoLoteCartao';
import FuncionarioImpresso from '../pages/Lote/FuncionarioImpresso';
import TelaImpressaoLotePessoas from '../pages/Impressao/TelaImpressaoLotePessoas';
import { isAuthenticated } from '../store/config/auth'



const PrivateRoute = ({component: Component,...rest}) => (
    <Route {...rest} render={props =>
    isAuthenticated() ? (
        <Component {...props}/>
    ) 
    :
    (
        <Redirect to={{ pathname: '/auth', state:{from: props.location}}}/>
      
    )
   
    }/>
    
    );
    
    const OpenRoute = ({component: Component,...rest}) => (
        <Route {...rest} render={props =>
        isAuthenticated() === false ? (
            <Component {...props}/>
        ) 
        :
        (
            <Redirect to={{ pathname: '/', state:{from: props.location }}} />
        )
        
        }/>
        );

export default props =>
    <Switch>
        <PrivateRoute exact path="/" component={ Funcionario}/>
        <OpenRoute exact path="/Auth" component={ Auth}/>
        <PrivateRoute exact path="/Importacao" component={ ImportacaoDados} />
        <PrivateRoute exact path="/Impressao/Cartao" component={ ImpressaoLoteCartao}/>
        <PrivateRoute exact path="/Impressao/Funcionario" component={ FuncionarioImpresso}/>
        <PrivateRoute exact path="/Impressao/Pessoas" component={ TelaImpressaoLotePessoas}/>
        <Redirect from='*' to='/'/>
    </Switch>