import './Nav.css'
import React from 'react'
/*import { Link } from 'react-router-dom'*/
import { NavLink } from 'react-router-dom'

export default function Nav() {
    return (
            <aside className="menu-area" >
                <nav className="menu" >
                    <ul className="menu-desktop">
                    <NavLink exact to="/">
                        <i className="fa fa-user"></i> Funcionario
                    </NavLink>
                     {/* <NavLink exact to="/Importacao">
                        <i className="fa fa-file"></i> Importação de Dados
                    </NavLink>  */}
                    <NavLink exact to="/Impressao/Cartao">
                        <i className="fa fa-print"></i>Impressão de Crachás
                    </NavLink>
                    <NavLink exact to="/Impressao/Funcionario">
                        <i className="fa fa-print"></i>Funcionários Impressos
                    </NavLink>
                    </ul>


                    {/* Menu Tablet e Celular */}

                    <ul className="menu-movel" >
                    <NavLink exact to="/">
                    Funcionario
                    </NavLink>
                     {/* <NavLink exact to="/Importacao">
                        <i className="fa fa-file"></i> Importação de Dados
                    </NavLink> */}
                    <NavLink exact to="/Impressao/Cartao">
                        <i className="fa fa-print"></i> Impressão de Crachás
                    </NavLink>
                    </ul>
                </nav>
            </aside>
    );
}
