import React,{Component} from "react";
import { format,parseISO,parse } from "date-fns";


import './ImpressaoLotePessoa.css'
import {get} from '../../store/config/register';

export default class ImpressaoLotePessoas extends Component{

  constructor(props){
    super (props);
    this.state = {
      Lote_id: props.Lote_id,
         Qtde: props.Qtde,
         Data: format(new Date(),'yyyy-MM-dd HH:mm:ss') ,
         Lote: []
    }
  }

    updateField( event){
    this.setState({Data: event.target.value});
} 
   
     renderCabec(){
        return(
            <div>
            <div className="row">
            < div className="col-12 col-md-6">
                <div className="form-group">
                <div className='tabela2'>
                <table className="row2" >
                <thead className="row2">
                    <tr className="row2" >
                    <th className="row2" scope="col">Email de:</th>
                    </tr>
          </thead>
                    <tbody className="row2" >
                    <tr className="row2" key={0} >
                    <td className="row2"  data-label="Email de:"><input type="date" class="form-control" name="Data"  value={this.state.Data} onChange={e => this.updateField(e)}/></td>           
                  </tr>
                    </tbody>
                </table>
                
            </div>
            </div>
            </div>
            < div className="col-12 col-md-6">
            <div className="form-group">
            <div className='tabela2'>
                <table className="row2" >
                <thead className="row2">
                    <tr className="row2" >
                    <th className="row2"  scope="col">Lote</th>
                    <th className="row2" scope="col">Qtde:</th>
                    </tr>
          </thead>
                    <tbody className="row2" >
                    <tr className="row2" key={0} >
                    <td className="row2" data-label="Lote">{this.state.Lote_id}</td>
                    <td className="row2"  data-label="Qtde:">{this.state.Qtde}</td>           
                  </tr>
                    </tbody>
                </table>
                
            </div>
            </div>
            </div>


            </div>
            <hr />
            </div>
        )
    }

     renderForm(){
        return(
            <div className='tabela'>
        <table>
          <thead>
            <tr>
              <th scope="col">SOLIC</th>
              <th scope="col">MATRÍCULA</th>
              <th scope="col" style={{width:"60%"}} >NOME</th>
              <th scope="col" style={{width:"20%"}}>SETOR</th>
            </tr>
          </thead>
            <tbody>
              {this.state.Lote === undefined ? null :this.state.Lote.map((item, index)=>{
    
           
                return(
                  <tr key={index} >
                    <td data-label="SOLIC">{index+1}</td>
                    <td data-label="MATRÍCULA">{item.matricula}</td>
                    <td data-label="NOME" >{item.nome}</td>
                    <td data-label="SETOR">{item.setor}</td>              
                  </tr>
                )     
              })}
            </tbody>
          </table>
    </div>
        );
    }

    async  buscaLote(Lote_id){
        const lot = await get(`/LoteImpCartaoCliente/v1/Lote/${Lote_id}`);
        if (lot!== null && lot !== ""){
          console.log(lot);
           this.setState({Lote: lot}) 
            }
        }
    
        UNSAFE_componentWillMount(){
          this.buscaLote(this.state.Lote_id);

          console.log(this.state.Lote)
     }

        render(){
          return (
            <div>
                {this.renderCabec()}
                {this.renderForm()}
            </div>
        );
        }
}


