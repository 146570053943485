import './Header.css'
import React from 'react'
import { useHistory } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';

import { isAuthenticated } from '../../store/config/auth';
import { getLocalStorage } from '../../store/config/storage';
import { authKey } from "../../global";

export default function Header(props){

  const history = useHistory();

  let user=undefined;
  if(getLocalStorage(authKey)){
     user = JSON.parse(getLocalStorage(authKey));
  }

  function logout(){
    localStorage.clear(authKey);
    history.push("/auth");
    window.location.reload();
  }

  return (
          <header className="header d-none d-sm-flex flex-column">
            <div className="item">
              <h1 className="mt-3">
                  <i className={`fa fa-${props.icon}`}></i> {props.title}
              </h1>
            
              <p className="lead text-muted">{props.subtitle}</p>
              {isAuthenticated() ? (<Dropdown>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                {user.nome}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="/">Home</Dropdown.Item>
                <Dropdown.Item onClick={()=>logout()}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>) 
            : 
            null
            }
                     
          </div>
        </header>
  );
}
