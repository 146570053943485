import React,{useEffect, useState} from "react";
import Main from "../../components/template/Main";
import { FiSearch,FiSend } from 'react-icons/fi';
import { parseISO, format } from "date-fns";
import {get} from '../../store/config/register';
import useTable from "../../components/table/useTable";
import TableFooter from "../../components/table/TableFooter";

const headerProps = {
    icon: "print",
    title: "Funcionários Impressos",
    subtitle: "Lista de Impressão"
  }

  

export default function FuncionarioImpresso(){

    const [pesquisa,setPesquisa]=useState('');
    const [lote,setLote]=useState([]);
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(lote, page, 10);


useEffect(()=>{
  buscaFuncionario();

},[])

    async function buscaFuncionario(pesquisa){
      if(pesquisa ===undefined){
        const lotes = await get("/LoteImpCartaoCliente/v1/Lote");
        setLote(lotes)
      }else{
        if (isNaN(pesquisa)){
        const lotes = await get("/LoteImpCartaoCliente/v1/Lote/Search/"+pesquisa);
        setLote(lotes)
        }else{
          const lotes = await get("/LoteImpCartaoCliente/v1/Lote/Matricula/"+pesquisa);
        setLote(lotes)
        }
      }
      
    }

    function  updateField( event){
      setPesquisa(event.target.value );
  }

    function renderTable(){
        return(    
            <div className='tabela'>
                <table>
                  <thead>
                    <tr>
                      <th scope="col">Lote</th>
                      <th scope="col">Data</th>
                      <th scope="col">Matricula</th>
                      <th scope="col">Funcionario</th>
                      <th scope="col">Setor</th>
                      <th scope="col">Impressao</th>
                    </tr>
                  </thead>
                    <tbody>
                      {slice.length === 0 ? null :slice.map((item, index)=>{
            
                                   
                        return(
                          <tr key={index} >
                            <td data-label="Lote">{item.lote_id}</td>
                            <td data-label="Data">{format(parseISO(item.data),"dd/MM/yyyy")}</td>
                            <td data-label="Matricula">{item.matricula}</td>
                            <td data-label="Funcionario">{item.nome}</td>  
                            <td data-label="Setor">{item.setor}</td>  
                            <td data-label="Impressao">{format(parseISO(item.impressao),"dd/MM/yyyy")}</td>        
                          </tr>
                        )     
                      })}
                    </tbody>
                  </table>
                  <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
            </div>
              );
      }

    function render(){
        return (
            <div>
            <h2>Pesquisa de Funcionário</h2>
    
            <div className="row1">
              <span>
              Funcionário: 
              </span>
              <div className="input-group mb-3">
                    <input type="text" class="form-control" name="pesquisa"  value={pesquisa} placeholder="Pesquise um funcionário" aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={e => updateField(e)}/>
                            <div className="input-group-append">
                            <button className="btn btn-info" type="button"  onClick={() => buscaFuncionario(pesquisa)}>Pesquisar</button>
                        </div>
                    </div>
            </div>

            {renderTable()}
          </div>
        );}

    return (
        <Main {...headerProps}>
            {render ()}
        </Main>
    );
}