import React,{useEffect, useState} from "react";
import Main from "../../components/template/Main";
import PesquisaCliente from '../../components/pesquisa/PesquisaCliente';
import { authKey } from "../../global";
import { getLocalStorage } from '../../store/config/storage';
import {get,post,put} from '../../store/config/register';
import { format,parse,parseISO } from "date-fns";
import './Funcionario.css';
import { FiUpload } from 'react-icons/fi';
import { toast } from 'react-toastify';

import { ptBR } from 'date-fns/locale'
import { DatePicker } from 'react-nice-dates'
import Compressor from 'compressorjs';


import avatar from '../../assets/imgs/avatar.png';


import 'react-nice-dates/build/style.css'

let idempresa=0;
let user=undefined;
  
const initialState ={
  funcionario: {
    id: 0,
    id_empresa: idempresa,
    matricula: 0,
    nome:'',
    nome_reduzido:'',
    rg: '',
    id_setor: 0,
    data_admissao: new Date(),
     },

     loteCliente:{
      lote_id: 0,
      data_impressao: format(new Date(),'yyyy-MM-dd HH:mm:ss'),
     }
}
let type='';

const headerProps = {
  icon: "users",
  title: "Funcionário",
  subtitle: "Cadastro de Funcionários"
}

const desativar =true;
export default function FuncionarioCadastro(){

    const [pesquisa,setPesquisa]=useState({id: 0, descricao: ''});
    const [funcionario,setFuncionario]=useState(initialState.funcionario);
    const [showPostPesquisa, setShowPostPesquisa] = useState(false);
    const [setores, setSetores]=useState([]);
    const [imagem,setImagem]=useState({});
    const [avatarUrl, setAvatarUrl] = useState(null);
    const [imageAvatar, setImageAvatar] = useState(null);
    const [temImagem,setTemImagem]=useState(0);
    const [loteCliente, setLoteCliente]=useState(initialState.loteCliente)

    const [lote, setLote]=useState({});

    useEffect(()=>{

        buscaSetor();
        if(getLocalStorage(authKey)){
          user = JSON.parse(getLocalStorage(authKey));
       }
    },[setores]);

    function setData(data){
      setFuncionario({...funcionario, data_admissao: data});
    }
   async function handleFile(e){

        if(e.target.files[0]){
          const image = e.target.files[0];
          type='';
          if(image.type === 'image/jpeg' || image.type === 'image/png'){
            // type=image.type;
            // setImageAvatar(image);
            // setAvatarUrl(URL.createObjectURL(e.target.files[0]))
            // let base64=await toBase64(image);          
            // if (type ==='image/jpeg'){
            //   setImagem({image:base64.replace('data:image/jpeg;base64,','')});
            // }else{
            //   setImagem({image:base64.replace('data:image/png;base64,','')});
            // }

            new Compressor( image, {
              quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
              success: async( compressedResult) => {
                // compressedResult has the compressed file.
                // Use the compressed file to upload the images to your server.        
                type=compressedResult.type;
                setImageAvatar(compressedResult);
                setAvatarUrl(URL.createObjectURL(compressedResult))
                let base64=await toBase64(compressedResult);          
                if (type ==='image/jpeg'){
                  setImagem({image: base64.replace('data:image/jpeg;base64,','')});
                }else{
                  setImagem({image: base64.replace('data:image/png;base64,','')});
                }
              },
            });
           

          }else{
            alert('Envie uma imagem do tipo PNG ou JPEG');
            setImageAvatar(null);
            setAvatarUrl(null);
            return null;
          }
    
        }
    
      }

      async function buscaClienteLote(id){
        const cli = await get(`/LoteImpCartaoCliente/v1/Lote/Cliente/${id}`);

        if(cli !== undefined){
    
          setLoteCliente(cli);

        } else{
          setLoteCliente(initialState.loteCliente)
        }
      }
   
      



      async function buscaLote(){
        const lot = await get(`/LoteImpCartao/v1/Empresa/${idempresa}`);
        if (lot!== null && lot!== '' ){
            setLote(lot);
            if(temImagem === 1){
              SaveLoteCliente(funcionario.id,lot.id);
              }else {
                alert("Funcionário não possui Foto");
              }
        }else {
          SaveLote();
        }
        }

      async function SaveLote(){
      let  lot= {
          id: 0,
          id_empresa: idempresa,
          data_abertura: new Date(),
          data_fechamento: null,
          data_impressao: null,
          id_usuario: user.id
           }

           lot = await post("/LoteImpCartao/v1",lot);
           lot = await get(`/LoteImpCartao/v1/Empresa/${idempresa}`);
           
        if (lot!== null){
          setLote(lot);
          if(temImagem === 1){
          SaveLoteCliente(funcionario.id,lot.id);
          }else {
            alert("Funcionário não possui Foto");
          }
        }
       }

       async function SaveLoteCliente(id,lote_id){
      
        let item =  {
          lote_id: lote_id,
          cliente_id: id,
          matricula: 0
        }
 
        await post("/LoteImpCartaoCliente/v1",item)
        toast.success("Funcionário adicionado ao lote.")
        window.location.reload();
       }
    function  updateFieldPesquisa(event){
        const pesq ={...pesquisa}
        pesq[event.target.name] =event.target.value   
        setPesquisa(pesq);
}
    function  updateField(event){
        const func ={...funcionario}
        
        func[event.target.name] =event.target.value
        if(event.target.name ==='matricula'){
          func[event.target.name] =parseInt(event.target.value)
        } else {
          func[event.target.name] =event.target.value
        }
        func["id_empresa"]=idempresa
        setFuncionario(func);
}

function togglePostModal(item){
    setShowPostPesquisa(!showPostPesquisa) //trocando de true pra false
    if(item.id !== 0){
      buscaClienteLote(item.id);
      buscaFuncionario(item.id);
      buscaImagem(item.id);
      
    }
}

async function buscaImagem(id){
  const imgs=await get(`/Imagem/v1/${id}`);
  if(imgs !=null){   
      if (imgs.img !== null){
        const image = dataURLtoFile(`data:${type};base64,${imgs.img}`,'teste.png')

     setImageAvatar(image);
    setAvatarUrl(URL.createObjectURL(image));
        
    setTemImagem(1);
    setImagem({image:imgs.img});
      }else {
        setAvatarUrl(null);
        setImageAvatar(avatar);
        setTemImagem(0);
      }

   }
}

function dataURLtoFile(dataurl, filename) {

  var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
      
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  
  return new File([u8arr], filename, {type:mime});
}


function buscaSetor(){
    const sets = get("/Setor/v1");

    if(sets != null){
        sets.then(resp =>{
          if(resp.length >0){
            resp.unshift( { id: 0, descricao: '' });
            idempresa=resp[1].id_empresa;
           
          }
            setSetores(resp);
    
       })
    }
}

function buscaFuncionario(ClienteId) {
  if(ClienteId !== undefined){
    const funcs = get("/Funcionario/v1/"+ClienteId);
    if(funcs != null){
        funcs.then(resp =>{
            setFuncionario({
            id: resp.id,
            id_empresa: resp.id_empresa,
            matricula: resp.matricula,
            nome: resp.nome,
            rg: resp.rg,
            id_setor: resp.id_setor,
            data_admissao: parse(resp.data_admissao,"yyyy-MM-dd'T'HH:mm:ss",new Date()),
            nome_reduzido: resp.nome_reduzido
          });
       })
    }
  }
  }

function trocaSetor(e) {
    setFuncionario({...funcionario, id_setor: parseInt(e.target.value),id_empresa: idempresa})
}

function renderSelectSetor(){    
    if(setores !== undefined){
        return setores.map(setor =>{
            return (<option key={setor.id} value={setor.id}>{setor.descricao}</option>)
        })
    }   
      
}
    function renderPesquisa(){
        return(
            <div className="form">
                 <label>Funcionário</label>
                    <div className="input-group mb-3 cli">
                        <input type="text" class="form-control" name="descricao"  value={pesquisa.descricao} placeholder="Pesquise um funcionário" aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={e => updateFieldPesquisa(e)}/>
                    <div className="input-group-append">
                    <button className="btn btn-info" type="button"  onClick={() => togglePostModal(pesquisa)}>Pesquisar</button>
                    </div>
                </div>
    
                {showPostPesquisa && (
            <PesquisaCliente
            conteudo={pesquisa}
            close={togglePostModal}
            />
            )}
            </div>
        );
    }

    
    async function saveImagem (){
      
      let dados = {
        id: funcionario.id,
        img:imagem.image
      }
     
      if (funcionario.id_setor !== 0){
        var resp=  await get(`/Funcionario/v1/exist/${funcionario.matricula}`);
        if(resp !== undefined){
          if(resp.contador == 0 || funcionario.matricula === 0){
           
              if(funcionario.id === 0){
                let resp =  await post(`/Funcionario/v1`,funcionario);
                setFuncionario({...funcionario,id: resp.id})
                dados.id =resp.id;
              }else{
                await put(`/Funcionario/v1/${funcionario.id}`,funcionario);
              }
        
              if(dados.img !== undefined){
              if(temImagem === 0){
                await post("/Imagem/v1",dados);
                setTemImagem(1);
              }else {
                await put(`/Imagem/v1/${funcionario.id}`,dados);
              }
            }
              toast.success("Dados atualizados com sucesso!");
            
           }else{
            if(funcionario.id === 0){
            toast.info("Já existe um funcionário cadastrado com está matrícula!");
            }else{
            //Atualizar
              
              await put(`/Funcionario/v1/${funcionario.id}`,funcionario);
              
              if(dados.img !== undefined){
              if(temImagem === 0){
                await post("/Imagem/v1",dados);
                setTemImagem(1);
              }else {
                await put(`/Imagem/v1/${funcionario.id}`,dados);
              }
            }
              toast.success("Dados atualizados com sucesso!");          
            }
           }
        }
      }else{
        toast.info("Selecione um setor!");
      }
    }

    // async function saveFuncionario (item){
    //   await post("/Funcionario/v1/planilha",item);
    // }

    function clear(){
      setFuncionario(initialState.funcionario);
      setImagem({});
      setAvatarUrl(null);
      setImageAvatar(null);
      setPesquisa({id: 0, descricao: ''});
      setLoteCliente(initialState.loteCliente)
    }

    const toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
  });

    function render(){
        return (
            <div className="form">
               <label className="label-avatar">
              <span>
                <FiUpload color="#FFF" size={25} />
              </span>

              <input type="file" accept="image/*" onChange={handleFile}  /><br/>
              { avatarUrl === null ? 
                <img src={avatar} width="250" height="250" alt="Foto de perfil do usuario" />
                :
                <img src={avatarUrl} width="250" height="250" alt="Foto de perfil do usuario" />
              }
            </label>
            

            { funcionario.id > 0 && loteCliente.lote_id !== 0 ? 
            (
              <div className="row">
              < div className="col-12 col-md-6">
                <div className="form-group">
                    <label> Data Lote </label>
                    <input type="datetime-local" name="data_lote" 
                            className="form-control" 
                            value={ loteCliente.data_impressao !== null ? format(parseISO(loteCliente.data_impressao),'yyyy-MM-dd HH:mm:ss'): format(new Date(),'yyyy-MM-dd HH:mm:ss')}
                            onChange={e => setLoteCliente ({data: e.value})} />
                </div>
              </div>
            </div>
            ) 
            : 
            null
            
            }
            

            <div className="row">
              < div className="col-12 col-md-6">
                <div className="form-group">
                <label>Matrícula</label>
                <input type="text" name="matricula" 
                            className="form-control" value={funcionario.matricula} 
                            onChange={e => updateField(e)} placeholder="Digite a matricula" />
                </div>
              </div>
                
                      
            </div> 

          
            <div className="nome-prof">
                  <label>Nome</label>
                  <input type="text" name="nome" 
                  className="form-control" value={funcionario.nome} 
                  onChange={e => updateField(e)} />
            </div>
            <br/>

            <div className="nome-prof">
                  <label>Nome Reduzido</label>
                  <input type="text" name="nome_reduzido" 
                  className="form-control" value={funcionario.nome_reduzido} 
                  onChange={e => updateField(e)} />
            </div>
            <div className="prof">
                    <label>Setor</label>
                    <select type="text" name="id_setor" className="form-control" 
                    value={funcionario.id_setor} onChange={trocaSetor} >{renderSelectSetor()}</select>
            </div>

            <div className="nome-prof">
                 
            </div>

              
            <hr/>
            <div id="botao">
                  <button className="btn btn-primary" onClick={e => saveImagem()}>
                    Salvar
                  </button>
                  <button className="btn btn-secondary ml-2" onClick={e => clear()}>
                    Cancelar
                  </button>
                  { temImagem && funcionario.id > 0 ? 
                  (

                    <button className="btn btn-outline-info ml-2" onClick={e =>buscaLote ()}>
                    Solicitar Crachá
                  </button>              
                  ) 
                  : 
                  null 
                  }
                   
            </div>
        </div>
        );
    }
    return(
        <Main {...headerProps}>
            {renderPesquisa()}
            {render()}
        </Main>
   
    );
}