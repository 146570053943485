import axiosInstance from './axios.js';
import {getLocalStorage} from './storage';


let token= {token: getLocalStorage("acessToken"),expiration: getLocalStorage("expiration")};

export const post=async(url,data)=>{
   
    if(validarAcesso()){
   const result=   await  axiosInstance.post(url,data)
        .then((response)=>{
          
         if(response.data != null){
             return response.data; 
            }
        })
        .catch(error =>{
            return null;
    })
    return result
   }
   return null;
}

export const put=(url,data)=>{

    if(validarAcesso()){
        axiosInstance.put(url,data)
        .then((response)=>{
       if(response.data != null){
        console.log(response.data);
            return JSON.parse(response.data) 
       }
    })
        .catch(error =>{
              
        console.log(error.response);
        return null;
     })
    }
    return null;
}

export const get=(url)=>{

    if(validarAcesso()){
         
        return axiosInstance.get(url)
        .then((response)=>{
       if(response.data != null){
        
        return response.data;
       }else{
           return null;
       }
    })
    .catch(error =>{
        console.log(error.response);
    })
    }
    return null;
}

export const del=(url)=>{

   if(validarAcesso()){

    return axiosInstance.delete(url)
    .then((response)=>{
       if(response.data != null){
        
         return response.data;
         }else{
           return null;
         }
        })
        .catch(error =>{
        console.log(error.response);
        })
   }
        return null;
 }

 function validarAcesso(){
    // if(token){

    //     if(token.expiration !== undefined){
    //         let agora=new Date();
    //         if (agora > token.expiration){
    //             clear();
    //             registrar();
    //         return true;
    //         }
    //     }else{
    //         clear();
    //         registrar();
    //         return true;
    //     }

    //     return true;
    // }else{
    //     clear();
    //     registrar();
    //    return true;
    // }
    return true;
 }

 function clear(){
    localStorage.clear("acessToken");
    localStorage.clear("expiration");
 }

export const registrar =async()=>{
    const data ={ usuario:"William", senha: "Teste"}
    await  axiosInstance.post("Usuario/v1",data)
    .then((response)=>{

        localStorage.setItem("acessToken",response.data.acessToken);
        localStorage.setItem("expiration",response.data.expiration);
        return {success: true}
    })
    .catch(error =>{
        console.log(error.response);
        clear();
        return {error:error.response, success: false };
    })
}