import { authKey } from "../../global";
import {getLocalStorage} from './storage';

export const isAuthenticated = () => {

 const user=getLocalStorage('authKey');

    if(user){
        return true;
    }else{
        return false;
    }
    
};